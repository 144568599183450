header {
  font-size: 1rem;
}

/* Navbar */
// set nav-items below navbar-brand
.navbar,
.navbar .container {
  display: block;
  padding-bottom: 0;
}

// nav-items
.nav-item {
  padding-right: 2rem;
  font-family: $zf-font-prometo-md;
  font-size: 1rem;
}

.nav-link {
  padding: 0.5rem 1rem 0.7rem;
}

// nav-item styles for expanded navbar
@mixin nav-item-in-expanded-navbar($expand-breakpoint) {
  .#{$expand-breakpoint} .nav-item {
    &.active > a,
    &:hover > a {
      color: $zf-blue;
      border-bottom: 2px solid;
      border-bottom-color: $zf-blue;
    }
    &:hover .disabled {
      border-bottom-color: transparent;
    }
  }
  .#{$expand-breakpoint}.navbar-light {
    &.nav-item {
      &:hover .disabled {
        border-bottom-color: transparent;
      }
    }
  }
  .#{$expand-breakpoint}.navbar-dark {
    &.nav-item {
      &:hover .disabled {
        border-bottom-color: transparent;
      }
    }
  }
}
// nav-item styles for collapsed navbar
@mixin nav-item-in-collapsed-navbar($expand-breakpoint) {
  .#{$expand-breakpoint} .navbar-collapse {
    width: 100%;
  }
  .#{$expand-breakpoint} .nav-item {
    color: cyan;
    border-bottom: 1px solid;
    border-bottom-color: grey;
    > a {
      color: inherit;
    }
  }
  .#{$expand-breakpoint},
  .#{$expand-breakpoint} .container {
    display: flex;
  }
}
// apply collapsed|expanded nav-item styles dependend on navbar breakpoint
@each $breakpoint, $value in $grid-breakpoints {
  @media (min-width: map-get($grid-breakpoints, $breakpoint)) {
    @include nav-item-in-expanded-navbar("navbar-expand-" + $breakpoint);
  }

  @media (max-width: map-get($grid-breakpoints, $breakpoint)) {
    @include nav-item-in-collapsed-navbar("navbar-expand-" + $breakpoint);
  }
}

// fix link colors outside of navbar-nav
.navbar .navbar-collapse a,
.navbar.navbar-light .navbar-collapse a {
  color: $navbar-light-color;
  &:hover {
    color: $navbar-light-hover-color;
  }
  .disabled {
    color: $navbar-light-disabled-color;
  }
}

.navbar.navbar-dark .navbar-collapse a {
  color: $navbar-dark-color;
  &:hover {
    color: $navbar-dark-hover-color;
  }
  .disabled {
    color: $navbar-dark-disabled-color;
  }
}

.navbar-toggler-icon {
  width: 2.2rem;
  height: 2.2rem;
}

.navbar-light .navbar-toggler-icon {
  width: 2.2rem;
  height: 2.2rem;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMCIgaGVpZ2h0PSIxOCIgdmlld0JveD0iMCAwIDMwIDE4Ij4KICA8ZyBpZD0iYnVyZ2VyLWljb24tc2xpbW1lciIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEzMi44NjMgLTE0OS41MDMpIj4KICAgIDxwYXRoIGlkPSJQYXRoXzY3NCIgZGF0YS1uYW1lPSJQYXRoIDY3NCIgZD0iTS4zMDYtLjExM2gyOCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTMzLjU1NyAxNTAuNjE1KSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMDBhYmU3IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIvPgogICAgPGxpbmUgaWQ9IkxpbmVfMjA5IiBkYXRhLW5hbWU9IkxpbmUgMjA5IiB4Mj0iMjgiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEzMy44NjMgMTU4LjUwMykiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzAwYWJlNyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjIiLz4KICAgIDxsaW5lIGlkPSJMaW5lXzIxMCIgZGF0YS1uYW1lPSJMaW5lIDIxMCIgeDI9IjI4IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMzMuODYzIDE2Ni41MDMpIiBmaWxsPSJub25lIiBzdHJva2U9IiMwMGFiZTciIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIi8+CiAgPC9nPgo8L3N2Zz4K");
}

.navbar-toggler {
  border: none;
  border-color: none;
}

.navbar-toggler * {
  font-size: 2.0625rem;
}

.navbar-brand {
  padding-top: 0.8rem;
  padding-bottom: 0.9rem;
  font-family: $zf-font-prometo-lt;

  > img {
    width: auto;
    max-width: 100%;
    min-height: 3.75rem;
    max-height: 3.75rem;
  }
  > span {
    display: block;
    float: right;
    margin-top: 0.79rem;
    margin-left: 1rem;
  }
}

@include media-breakpoint-down(xs) {
  .navbar-brand {
    padding-bottom: 0.8rem;

    > img {
      min-height: 2.65rem;
      max-height: 2.65rem;
    }
    > span {
      padding-top: 0;
      padding-left: 0;
      margin-top: 0.5rem;
      margin-left: 0.6rem;
      font-size: 1.05rem;
    }
  }
}
