.btn {
  padding-top: 0.3rem;
}

.btn-lg {
  padding: 0.3rem 1.5rem 0.4rem;
  font-size: 1.0625rem;
  line-height: 2.35rem;
}

// make button color lighter instead of darker on hover events
@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant(
      $value,
      $value,
      theme-color-level($color, -3.63),
      theme-color-level($color, -3.63),
      theme-color-level($color, 2),
      theme-color-level($color, 2)
    );
  }
  .btn-outline-#{$color} {
    border-width: 2px;
  }
}
