$font-path: "../fonts/" !default;

@font-face {
  font-family: Prometo-Light;
  font-style: normal;
  src: url("#{$font-path}ZFPrometo_W_Lt.woff") format("woff");
}

@font-face {
  font-family: Rubik-Medium;
  font-style: normal;
  src: url("#{$font-path}ZFPrometo_W_Md.woff") format("woff");
}

.zf-font-prometo-md {
  font-family: Rubik-Medium, sans-serif;
}

.zf-font-prometo-light {
  font-family: Prometo-Light, sans-serif;
}
