/** Accordeons **/

.accordion .card-header {
  padding: 0.2rem 0;
  background-color: transparent;
  border: 0;
}

.accordion .card {
  background-color: transparent;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
}

.accordion .card-body {
  padding: 0 2.2rem 1rem 0.1rem;
}

.accordion > .card:first-of-type {
  border-top: 0;
}

.accordion .btn {
  width: 100%;
  padding-left: 0;
  text-align: left;

  /* Icon for expanded accordion card */
  &::after {
    position: absolute;
    top: 0.8rem;
    right: 0;
    display: inline-block;
    width: 2rem;
    height: 2rem;
    content: "";
    background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyMiIgdmlld0JveD0iMCAwIDIyIDIyIj4NCiAgPGcgaWQ9ImFycm93LXRvcCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEwOTQgLTQxMikiPg0KICAgIDxnIGlkPSJhcnJvdy10b3AtMiIgZGF0YS1uYW1lPSJhcnJvdy10b3AiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEzNDguNTQgODQ2LjA3Nykgcm90YXRlKDE4MCkiPg0KICAgICAgPHBhdGggaWQ9IlBhdGhfMSIgZGF0YS1uYW1lPSJQYXRoIDEiIGQ9Ik0yMzcuNTQsNDIwLjA5MWw1Ljk4MSw1Ljk4MWEuMDIyLjAyMiwwLDAsMCwuMDMxLDBsNS45ODctNS45OTIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDApIiBmaWxsPSJub25lIiBzdHJva2U9IiMwMGFiZTciIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIi8+DQogICAgPC9nPg0KICAgIDxyZWN0IGlkPSJSZWN0YW5nbGVfMiIgZGF0YS1uYW1lPSJSZWN0YW5nbGUgMiIgd2lkdGg9IjIyIiBoZWlnaHQ9IjIyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMDk0IDQxMikiIGZpbGw9Im5vbmUiLz4NCiAgPC9nPg0KPC9zdmc+DQo=")
      no-repeat;
    background-size: 1.34rem 1.34rem;
  }

  /* Arrow icon for collapsed accordion card */
  &.collapsed::after {
    background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyMiIgdmlld0JveD0iMCAwIDIyIDIyIj4NCiAgPGcgaWQ9ImFycm93LWJvdHRvbSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEwMzggLTQxMikiPg0KICAgIDxnIGlkPSJhcnJvdy1ib3R0b21tIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg4MDUuNDYyIC0wLjA4MikiPg0KICAgICAgPHBhdGggaWQ9IlBhdGhfMSIgZGF0YS1uYW1lPSJQYXRoIDEiIGQ9Ik0yMzcuNTQsNDIwLjA5MWw1Ljk4MSw1Ljk4MWEuMDIyLjAyMiwwLDAsMCwuMDMxLDBsNS45ODctNS45OTIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDApIiBmaWxsPSJub25lIiBzdHJva2U9IiMwMGFiZTciIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIi8+DQogICAgPC9nPg0KICAgIDxyZWN0IGlkPSJSZWN0YW5nbGVfMSIgZGF0YS1uYW1lPSJSZWN0YW5nbGUgMSIgd2lkdGg9IjIyIiBoZWlnaHQ9IjIyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMDM4IDQxMikiIGZpbGw9Im5vbmUiLz4NCiAgPC9nPg0KPC9zdmc+DQo=")
      no-repeat;
    background-size: 1.34rem 1.34rem;
  }
}
