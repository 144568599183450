.spinner-border {
  color: $zf-blue;
  border-top-color: #e0e5ec;
  border-right-color: #e0e5ec;
  border-left-color: #e0e5ec;
}

.spinner-border-xl {
  width: 6rem;
  height: 6rem;
}

.spinner-border-lg {
  width: 5rem;
  height: 5rem;
}

.spinner-border-md {
  width: 4rem;
  height: 4rem;
}

.btn .spinner-border {
  width: 1.1rem;
  height: 1.1rem;
  margin-right: 0.3rem;
  margin-left: -0.2rem;
  border-width: 0.15rem;
}
