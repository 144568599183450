.calendar-control {
  display: flex;
  flex-wrap: wrap;
  user-select: none;
  align-items: stretch;

  .day-box {
    flex: 1 1 0;
    width: 0;
    display: grid;
    grid-template-rows: 1fr auto;
    grid-template-columns: 1fr;
    margin: 3px;

    .day {
      font-family: $zf-font-prometo-md;
      font-weight: 500;
      font-size: 18px;
      text-align: center;
    }

    .weekday {
      font-size: 10px;
      font-style: italic;
      text-align: center;
    }

    .hours-per-day {
      font-family: $zf-font-prometo-md;
      font-weight: 500;
      color: $zf-black-25;
      font-size: 12px;
      text-align: center;
    }

    .hours {
      font-family: $zf-font-prometo-md;
      font-weight: 500;
      color: $zf-cyan-100;
      font-size: 18px;
      text-align: center;

      &.edited {
        color: $zf-yellow;
      }
    }

    .box {
      border: 1px solid #ddd;
      border-radius: 4px;
      box-shadow: 1px 1px 1px rgba(black, 0.1);
      display: grid;
      grid-template-columns: 1fr 1fr;
      transition: background-color 400ms linear;
      cursor: pointer;
      padding: 1px;

      &.selected {
        background-color: $zf-cyan-25;
        border: 2px solid $zf-cyan-100;
        box-shadow: 1px 1px 1px rgba($zf-cyan-100, 0.1);
        padding: 0;
      }

      &.off {
        background-color: $zf-grey;
      }

      &.sunday {
        .day,
        .weekday {
          color: mix($zf-dark-blue, $zf-red, 5);
        }
      }

      &:hover {
        box-shadow: 1px 1px 7px rgba(black, 0.25);
        background-color: $zf-cyan-25;
      }

      .box-column {
        display: flex;
        flex-direction: column;

        div {
          flex: 1 1 0;
        }
      }
    }
  }

  .day-settings {
    text-align: center;
    height: 0;
    transition-property: height;
    transition-duration: 0.5s;
    transition-timing-function: ease-out;

    input {
      height: 0;
      transform: scale(0.1);
      transition-property: transform height;
      transition-duration: 0.5s;
      transition-timing-function: ease-out;
    }

    &.active {
      height: 30px;

      input {
        height: 0;
        height: 30px;
        transform: scale(1.5);
      }
    }
  }
}

@media screen and (max-width: 1680px) {
  .calendar-control .day-box .box {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 940px) {
  .calendar-control {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
  }

  .calendar-control .day-box .box {
    grid-template-columns: 1fr 1fr;
  }

  .calendar-control .day-box {
    width: unset;
  }
}
