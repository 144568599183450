/* Links: always use pointer cursor, even if 'href' attribute is missing (e.g. Angular application) */

a:hover {
  cursor: pointer;
}

/* Text Selection Color:
Overwrites the default text selection color
to match the ZF CD for a more pleasant look. */
::selection {
  color: $white;
  background: $zf-cyan-100;
}

/* Remove browser specific focus borders */
:focus {
  outline: none;
}

button:focus {
  outline: none;
}

/* List bullet point styling */

.zf-list {
  list-style: none;
}

.zf-list > li::before {
  display: inline-block;
  width: 1em;
  margin-right: 0.2em;
  margin-left: -1.2em;
  color: $zf-cyan-100;
  content: "\2022";
}

/* ZF Gradient */

.zf-gradient-background {
  background: linear-gradient(180deg, #00223e 0, $zf-blue 100%) no-repeat
    padding-box;
}

.zf-gradient-background-reversed {
  background: transparent
    linear-gradient(360deg, #002948 0%, #004d7a 56%, #0070b4 95%, #0070b4 100%)
    0% 0% no-repeat padding-box;
}

/* Footer */

.zf-footer {
  position: absolute;
  width: 100%;
  padding: 1.5rem;
  color: $zf-white;
  background: #001024;
}

/* Login Form */

.form-signin {
  max-width: 400px;
}

/* Floating Labels */

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group > input,
.form-label-group > label {
  height: 3.125rem;
  padding: 0.75rem;
}

.form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0; /* Override default `<label>` margin */
  line-height: 1.5;
  color: $zf-secondary;
  pointer-events: none;
  cursor: text; /* Match the input under the label */
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition-timing-function: ease-in-out;
  transition-duration: 0.1s;
  transition-property: all;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}

.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: $zf-cyan-100;
}

/* Fallback for Edge
-------------------------------------------------- */
@supports (-ms-ime-align: auto) {
  .form-label-group > label {
    display: none;
  }
  .form-label-group input::-ms-input-placeholder {
    color: #777;
  }
}

/* Fallback for IE
-------------------------------------------------- */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .form-label-group > label {
    display: none;
  }
  .form-label-group input::-ms-input-placeholder {
    color: #777;
  }
}
