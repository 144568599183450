/* Responsive Breakpoints */
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1550px
) !default;

/* Type */
$lead-font-size: 1.2rem !default;
$code-font-size: 0.85rem !default;

/* Colors */
$white: $zf-white !default;
$black: $zf-black !default;
$blue: $zf-blue !default;
$red: $zf-red !default;
$cyan: $zf-cyan-100 !default;
$green: $zf-green !default;
$yellow: $zf-yellow !default;

$colors: () !default;
$colors: map-merge(
  (
    "blue": $blue,
    "red": $red,
    "yellow": $yellow,
    "green": $green,
    "cyan": $cyan,
    "white": $white,
    "gray": $zf-grey,
    "gray-dark": $zf-dark-grey
  ),
  $colors
);

// extend Bootstrap's greyscale with ZF greys (https://getbootstrap.com/docs/4.1/getting-started/theming/#grays)
$grays: (
  "zf-black": $zf-black,
  "zf-dark-grey": $zf-dark-grey,
  "zf-black-50": $zf-black-50,
  "zf-black-25": $zf-black-25,
  "zf-grey": $zf-grey
) !default;

/* Theming Colors */
$primary: $zf-cyan-100 !default;
$secondary: $zf-secondary !default;
$success: $zf-green !default;
$danger: $zf-red !default;
$warning: $zf-yellow !default;
$info: $zf-blue !default;
$light: $zf-grey !default;
$dark: $zf-dark-blue !default;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark
  ),
  $theme-colors
);

$theme-color-interval: 5% !default;

/* Options */
$enable-shadows: true !default;

$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075) !default;
$box-shadow: 0 0.3rem 2rem rgba($black, 0.07) !default;
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175) !default;

/* Radius */
$border-radius: 0.5rem !default;
$border-radius-lg: 0.75rem !default;
$border-radius-sm: 0.25rem !default;

/* Body Element */
$body-bg: $zf-grey !default;
$body-color: $zf-dark-blue !default;

/* Fonts */
// see https://web-mp01.emea.zf-world.com/sites/2418/uxportal/styleguide-basic-elements.html#ui-fonts
$font-family-sans-serif: $zf-font-verdana-rg !default;
$font-family-base: $font-family-sans-serif !default;
$font-size-base: 0.875rem !default; // 14px

/* Link Anchor Elements */
$link-color: $primary !default;
$link-hover-color: $zf-cyan-75 !default;
$link-hover-decoration: none !default;

/* Spacer */
$spacer: 1rem !default;

/* Headings */
$headings-font-family: $zf-font-prometo-md !default;
$headings-line-height: 1.3 !default;
$headings-margin-bottom: $spacer / 1 !default;

$h3-font-size: $font-size-base * 1.78575 !default;
$h5-font-size: $font-size-base * 1.28571428 !default;

/* Displays */
$display1-size: 3.75rem !default;
$display2-size: 3.25rem !default;
$display3-size: 2.75rem !default;
$display4-size: 2.25rem !default;

/* Tables */
$table-border-width: 0 !default;
$table-accent-bg: $zf-grey !default;
$table-hover-bg: #d6e7f2 !default;
$table-head-bg: transparent !default;
$table-head-color: $zf-blue !default;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-focus-width: 0 !default;
$input-btn-focus-color: $zf-cyan-50 !default;
$input-btn-focus-box-shadow: none !default;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-x: 1rem !default;
$btn-padding-y: 0.5rem !default;
$btn-font-family: $zf-font-prometo-md !default;
$btn-font-size: 16px !default;

$btn-padding-x-sm: 0.75rem !default;
$btn-padding-y-sm: 0.25rem !default;
$btn-font-size-sm: 14px !default;

$btn-box-shadow: none !default;
$btn-focus-box-shadow: none !default;
$btn-disabled-opacity: 0.35 !default;
$btn-active-box-shadow: none !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: 3rem !default;
$btn-border-radius-lg: 3rem !default;
$btn-border-radius-sm: 3rem !default;

$btn-transition: color 0s ease-in-out, background-color 0.07s ease-in-out,
  border-color 0.07s ease-in-out, box-shadow 0.07s ease-in-out !default;

// Forms

$input-padding-y: 0.5625rem !default;

$input-box-shadow: none !default;

$input-border-radius: $border-radius-sm !default;

$input-focus-border-color: $zf-blue !default;
$input-focus-box-shadow: none !default;

/* Selects */
$custom-select-box-shadow: none !default;
$custom-select-focus-box-shadow: none !default;
$custom-select-border-radius: $border-radius-sm !default;
$custom-select-bg-size: 22px 22px !default; // In pixels because image dimensions
$custom-select-indicator: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyMiIgdmlld0JveD0iMCAwIDIyIDIyIj4NCiAgPGcgaWQ9ImFycm93LWJvdHRvbSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEwMzggLTQxMikiPg0KICAgIDxnIGlkPSJhcnJvdy1ib3R0b21tIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg4MDUuNDYyIC0wLjA4MikiPg0KICAgICAgPHBhdGggaWQ9IlBhdGhfMSIgZGF0YS1uYW1lPSJQYXRoIDEiIGQ9Ik0yMzcuNTQsNDIwLjA5MWw1Ljk4MSw1Ljk4MWEuMDIyLjAyMiwwLDAsMCwuMDMxLDBsNS45ODctNS45OTIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDApIiBmaWxsPSJub25lIiBzdHJva2U9IiMwMGFiZTciIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIi8+DQogICAgPC9nPg0KICAgIDxyZWN0IGlkPSJSZWN0YW5nbGVfMSIgZGF0YS1uYW1lPSJSZWN0YW5nbGUgMSIgd2lkdGg9IjIyIiBoZWlnaHQ9IjIyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMDM4IDQxMikiIGZpbGw9Im5vbmUiLz4NCiAgPC9nPg0KPC9zdmc+DQo=") !default;

/* Range */
$custom-range-track-box-shadow: none !default;

/* Navbars */
$navbar-brand-font-size: 1.3125rem !default;

$navbar-dark-color: $zf-white !default;
$navbar-dark-hover-color: $zf-white !default;
$navbar-dark-active-color: $zf-white !default;
$navbar-dark-disabled-color: $zf-black-50 !default;
$navbar-dark-toggler-border-color: $zf-dark-grey !default;

$navbar-light-color: $zf-cyan-100 !default;
$navbar-light-hover-color: $zf-blue !default;
$navbar-light-active-color: $zf-blue !default;
$navbar-light-disabled-color: $zf-black-25 !default;
$navbar-light-toggler-border-color: $zf-black-50 !default;

$navbar-light-brand-color: $zf-blue !default;
$navbar-light-brand-hover-color: $zf-blue !default;
$navbar-dark-brand-color: $zf-white !default;
$navbar-dark-brand-hover-color: $zf-white !default;

$navbar-padding-x: 0 !default;
$navbar-nav-link-padding-x: 0 !default;

/* Badges */
$badge-padding-y: 0.4em !default;
$badge-padding-x: 0.8em !default;
$badge-border-radius: 1rem !default;

/* Alerts */
$alert-bg-level: -15 !default;
$alert-border-level: -15 !default;
$alert-color-level: 7 !default;
$alert-padding-x: 1.1rem !default;

/* Close Buttons */
$close-text-shadow: none !default;
$close-font-weight: 300 !default;

/* Pagination */
$pagination-border-color: transparent !default;
$pagination-hover-bg: $zf-grey !default;
$pagination-hover-border-color: transparent !default;
$pagination-active-border-color: transparent !default;
$pagination-disabled-color: $zf-black-50 !default;
$pagination-disabled-bg: $white !default;
$pagination-disabled-border-color: transparent !default;

/* Tooltip */
$tooltip-bg: $zf-dark-grey !default;
$tooltip-border-radius: 0.25rem !default;

/* Popover */
$popover-bg: $zf-white !default;
$popover-border-width: 1px !default;
$popover-border-color: $zf-white !default;
$popover-border-radius: 6px !default;
$popover-box-shadow: 0 0.5rem 2rem 0 rgba(0, 0, 0, 0.2) !default;

/* Spinner */
$spinner-width: 3rem !default;
$spinner-border-width: 0.3em !default;
$spinner-width-sm: 2rem !default;
$spinner-border-width-sm: 0.245em !default;

/* Progress */
$progress-border-radius: 1rem !default;

/* Modals */
$modal-content-border-width: 0 !default;
$modal-content-border-radius: $border-radius !default;
$modal-transition: transform 0.2s ease-out !default;
$modal-inner-padding: 2.5rem !default;
$modal-header-padding-y: 2rem !default;
$modal-header-padding-x: 2.5rem !default;
$modal-backdrop-opacity: 0.1 !default;
$modal-content-box-shadow-xs: 0 5px 15px rgba(0, 0, 0, 0.3) !default;
$modal-content-box-shadow-sm-up: 0 5px 15px rgba(0, 0, 0, 0.3) !default;

/* Dropdown */
$dropdown-border-width: 0 !default;

/* Custom Controls */
$custom-control-indicator-size: 1.25rem !default;
$custom-checkbox-indicator-border-radius: $border-radius-sm !default;
$custom-switch-width: $custom-control-indicator-size * 2 !default;
$custom-control-cursor: pointer !default;
$custom-control-indicator-checked-box-shadow: none !default;
$custom-control-indicator-active-box-shadow: none !default;
$custom-checkbox-indicator-indeterminate-box-shadow: none !default;
