.tooltip-btn {
  &.disabled {
    opacity: 0.35;
    cursor: not-allowed;
    background: none;

    &:hover {
      background: none !important;
      box-shadow: none;
      outline: none;
    }
  }

  &.loading {
    padding: 10px !important;
  }
}
