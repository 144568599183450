.date-tag {
  font-weight: 500;
  font-family: Rubik-Medium, sans-serif;
  font-size: 13px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 30px;
  padding: 0px 5px;
  border-radius: 7px;
  background-color: $zf-cyan-100;
  transition: background-color 400ms linear;
  color: white;

  .mi-icon path {
    fill: white;
  }

  .date {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 4px 0 5px;
  }

  .year {
    font-size: 10px;
    color: $zf-cyan-75;
  }

  &.gray {
    background-color: $zf-black-25;
    > div {
      small,
      .year {
        color: $zf-grey;
      }
    }
  }

  &.yellow {
    background-color: $zf-yellow;
    > div {
      small,
      .year {
        color: lighten($zf-yellow, 30);
      }
    }
  }

  &.red {
    background-color: $zf-red;
    > div {
      small,
      .year {
        color: lighten($zf-red, 30);
      }
    }
  }

  &.green {
    background-color: $zf-green;
    > div {
      small,
      .year {
        color: lighten($zf-green, 30);
      }
    }
  }

  &.white {
    background-color: white;
    color: $zf-cyan-100;
    .mi-icon path {
      fill: $zf-cyan-100;
    }
  }
}
