header.topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border-bottom: 1px solid #ddd;
  gap: 5px;

  .trademark {
    display: flex;
    align-items: center;

    .app-name {
      font-family: Prometo-Light, sans-serif;
      font-weight: 600;
      font-size: 20px;
      color: $zf-blue;
      padding-left: 10px;
    }
  }

  .search {
    margin-left: 20px;
  }

  .btn:hover {
    background: $zf-cyan-25;
  }

  .month-selector {
    display: flex;
    font-family: Rubik-Medium, sans-serif;
    font-size: 19px;
    text-align: center;
    align-items: center;

    .mi-icon path {
      fill: black;
    }

    .month-selector-back {
      font-size: 12px;
    }

    div {
      min-width: 150px;

      .link {
        color: $zf-blue;
      }
    }
  }

  .hours {
    display: grid;
    grid-template-columns: repeat(3, auto);
    column-gap: 5px;
    justify-items: center;

    .hours-value {
      font-weight: 200;
      font-size: 20px;
      font-family: $zf-font-prometo-md;
    }
  }
}

@media screen and (min-width: 940px) {
  header.topbar {
    flex-wrap: wrap;

    .search {
      margin-left: 20px;
    }

    .btn {
      padding: 0.5em 0.5em;
    }

    .month-selector-back {
      font-size: 12px;
    }
  }
}

@media screen and (max-width: 940px) {
  header.topbar {
    flex-wrap: wrap;

    .spacer {
      display: none;
    }
  }
}
