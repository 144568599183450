.hours-tag-input {
  font-weight: 500;
  font-family: Rubik-Medium, sans-serif;
  font-size: 17px;
  line-height: 1;
  display: grid;
  grid-template-columns: auto auto;
  border-radius: 7px;
  border-style: dashed;
  border-color: $zf-cyan-25;
  transition: border-color 400ms linear;
  padding: 0;

  input {
    width: 3.1rem;
    height: 20px;
    text-align: end;
    color: $zf-cyan-100;
    transition: color 400ms linear;
    border: none;
    background-color: unset;
  }

  > div {
    margin: 3px;

    small {
      color: $zf-cyan-25;
      transition: color 400ms linear;
    }
  }

  .mi-icon path {
    fill: $zf-cyan-100;
    transition: fill 400ms linear;
  }

  &.gray {
    border-color: lighten($zf-black-25, 15);
    > div {
      small {
        color: lighten($zf-black-25, 15);
      }
    }
    input {
      color: $zf-black-25;
    }
    .mi-icon path {
      fill: $zf-black-25;
    }
  }

  &.yellow {
    border-color: lighten($zf-yellow, 30);
    > div {
      small {
        color: lighten($zf-yellow, 30);
      }
    }
    input {
      color: $zf-yellow;
    }
    .mi-icon path {
      fill: $zf-yellow;
    }
  }

  &.red {
    border-color: lighten($zf-red, 30);
    > div {
      small {
        color: lighten($zf-red, 30);
      }
    }
    input {
      color: $zf-red;
    }
    .mi-icon path {
      fill: $zf-red;
    }
  }

  &.green {
    border-color: lighten($zf-green, 30);
    > div {
      small {
        color: lighten($zf-green, 30);
      }
    }
    input {
      color: $zf-green;
    }
    .mi-icon path {
      fill: $zf-green;
    }
  }
}
