body {
  background-color: white;
  overflow: hidden;
}

.background-cat {
  position: absolute;
  height: 200px;
  width: 200px;
  background: url('../assets/img/SmartPTRClock.png') right bottom no-repeat;
  background-size: 170px;
  bottom: 10px;
  right: 20px;
  z-index: -10;
}

.mi-icon path {
  fill: $zf-blue;
}

.mi-icon.edited path {
  fill: $zf-yellow;
}

.btn-primary {
  .mi-icon path {
    fill: white;
  }
}

.bp3-heading {
  font-family: Prometo-Light, sans-serif;
  font-weight: 600;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .tiles-fliper {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.spacer {
  flex-grow: 1;
}

.search {
  display: block;
  position: relative;

  > .mi-icon {
    position: absolute;
    top: 0;
    z-index: 1;
    margin: 2px;
  }

  > input {
    padding-left: 30px;
    height: 30px;
  }

  .close-button {
    position: absolute;
    right: -5px;
    top: -2px;
  }
}

.tiles {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(325px, 1fr));

  .tile {
    margin: 8px;
    border: 1px solid #bbb;
    box-shadow: 1px 1px 1px rgba(black, 0.1);
    background-color: #fff;
    border-radius: 4px;
    transition: background-color 400ms linear;

    &:hover {
      box-shadow: 1px 1px 7px rgba(black, 0.25);
    }

    header {
      background-color: $zf-grey;
      transition: background-color 400ms linear;
      margin: 2px;
      display: grid;
      grid-template-columns: auto 1fr auto;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;

      .title {
        font-family: Rubik-Medium, sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.3;
        padding: 5px;
      }

      .buttons {
        padding: 5px;
      }

      button {
        padding: 0;
        border: none;
        background: none;

        .mi-icon path {
          fill: $zf-black-25;
          transition: fill 400ms linear;
        }

        &:hover {
          .mi-icon path {
            fill: $zf-blue;
          }
        }

        &.pinned {
          .mi-icon path {
            fill: $zf-blue;
          }
        }
      }
    }

    hr {
      padding: 0;
      margin: 0;
    }

    .tile-hours {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 5px;
      gap: 10px;
    }

    .tile-details {
      padding: 4px;

      .tile-title {
        font-weight: 500;
        font-family: Rubik-Medium, sans-serif;
      }

      .tile-description {
        grid-column: 1;
        grid-row: 2;
      }
    }

    .textarea {
      padding: 5px;
    }

    &.active {
      background-color: $zf-cyan-25;

      header {
        background-color: $zf-cyan-100;
        color: white;

        .highlight {
          color: $zf-cyan-100;
        }
      }

      button {
        .mi-icon path {
          fill: white;
        }

        &:hover {
          .mi-icon path {
            fill: $zf-blue;
          }
        }

        &.pinned {
          .mi-icon path {
            fill: $zf-blue;
          }
        }
      }

      .tile-hours {
        .hours-tag-input {
          border-color: $zf-cyan-50;

          > div {
            small {
              color: $zf-cyan-50;
            }
          }
        }
      }
    }
  }
}

.progress {
  border-radius: 0;

  .progress-bar {
    border-radius: 0;
  }

  .progress-striped {
    background-color: transparent;
    background-image: linear-gradient(
      45deg,
      $zf-yellow 25%,
      transparent 25%,
      transparent 50%,
      $zf-yellow 50%,
      $zf-yellow 75%,
      transparent 75%,
      transparent
    );
    background-size: 1rem 1rem;
  }
}

.day-detail {
  display: flex;
  justify-content: space-around;
  overflow: auto;
  min-height: 50px;
  border-bottom: 1px solid #ddd;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;

    > h4 {
      margin: 0;
    }
  }

  .line {
    padding: 5px;
    display: grid;
    grid-template-columns: auto 50px;

    &:hover {
      background-color: $zf-grey;
    }

    .project {
      font-weight: 500;
      font-family: Rubik-Medium, sans-serif;
      font-size: 17px;
      color: $zf-blue;
    }

    .title {
      font-size: 17px;
      font-weight: 500;
      font-family: Rubik-Medium, sans-serif;
      color: $zf-dark-blue;
    }

    .description {
      color: $zf-black-50;
      font-style: italic;
    }

    .hours {
      font-size: 20px;
      font-family: $zf-font-prometo-md;
      place-self: center;
    }

    .comment {
      place-self: center;
      font-style: italic;
    }

    .edit {
      background-color: $zf-grey;
    }
  }

  .edit {
    padding: 5px;
    display: grid;
    grid-template-columns: auto 100px 100px;
    grid-template-rows: auto auto;
    gap: 5px;
    height: 0;
    visibility: hidden;

    > .select {
      .bp3-popover-wrapper,
      .bp3-popover-target,
      button {
        height: 100%;
      }
    }

    > input {
      grid-column-start: 2;
      grid-column-end: span 2;
    }

    &.visible {
      visibility: visible;
      height: auto;
    }
  }

  .buttons {
    display: grid;
    place-items: center;
    padding: 5px;
  }

  .day-line {
    display: grid;
    grid-template-columns: 150px 100px auto;
  }

  .error {
    font-family: Rubik-Medium, sans-serif;
    font-weight: 100;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
  }

  input {
    width: 100%;
  }

  .tile {
    padding: 8px;
    margin: 8px;
    border: 1px solid #bbb;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    border-radius: 4px;
    display: grid;
    grid-template-columns: 1fr 1fr;

    .tile-project {
      font-family: Rubik-Medium, sans-serif;
      font-weight: 100;
      font-size: 18px;
      line-height: 21px;
    }

    .tile-hours {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      justify-items: center;
      column-gap: 10px;

      .title-hour {
        font-family: Rubik-Medium, sans-serif;
        font-weight: 100;
        font-size: 20px;
      }
    }
  }
}

.page {
  display: grid;
  grid-template-columns: auto 750px;
  overflow: hidden;
}

.settings-popover {
  padding: 10px;
  min-width: 200px;

  > div {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 10px;
  }

  input {
    width: 40px;
    height: 20px;
    padding: 0 5px;
  }
}

.print-popover {
  padding: 10px;

  h5 {
    text-align: center;
    margin: 0 0 0.6rem 0;
  }

  > div {
    display: grid;
    grid-template-columns: auto auto;
  }

  input {
    width: 40px;
    height: 20px;
    padding: 0 5px;
  }

  .btn {
    &:hover {
      background: rgba(125, 188, 255, 0.6);
    }
  }
}

.day-body {
  .detail {
    padding-left: 10px;
    padding-right: 10px;
    height: 450px;
    display: flex;
    flex-direction: column;
    // height: 100vh;

    .tiles-fliper {
      flex-grow: 1;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  .calendar {
    border-right: 1px solid #ddd;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px;
    padding: 10px;

    .day {
      background-color: white;
      padding: 10px;
      height: 54px;
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
      border: 1px solid #bbb;

      &:hover {
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);

        input {
          border-style: dashed;
          border-color: $zf-cyan-25;
          border-width: 2px;

          &:disabled {
            border-color: white;
          }
        }

        &.off {
          input {
            border-color: white;

            &:disabled {
              border-color: $zf-grey;
            }
          }
        }
      }
    }
  }
}

.bp3-dialog {
  width: 800px;
}

.element-detail {
  border-bottom: none;

  .calendar {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px;
    padding: 10px;

    .bp3-popover2-target {
      width: 100%;
    }

    .day {
      background-color: white;
      height: 74px;
      padding: 4px;
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
      border: 1px solid #bbb;
      line-height: 10px;

      .box {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-template-rows: auto auto;
        grid-template-areas:
          'date comment'
          'hours hours';

        .box-comment {
          grid-area: comment;
          text-align: right;
        }

        .hours {
          grid-area: hours;
        }
      }

      .date,
      .hours {
        font-family: 'Prometo-Medium', sans-serif;
        font-weight: 500;
        font-size: 18px;
      }

      .date {
        padding: 5px;
      }

      .hours {
        color: $zf-cyan-100;
        text-align: right;
        border: none;
        background: none;
        font-size: 2.1rem;
        height: 2.3rem;
        border-style: dashed;
        border-color: white;
        border-width: 2px;

        &.edited {
          color: $zf-yellow;
        }
      }

      &.off {
        background-color: $zf-grey;

        .hours {
          border-color: $zf-grey;
        }
      }

      .comment-icon {
        .mi-icon path {
          fill: none;
        }

        &:hover {
          .mi-icon path {
            fill: rgba($zf-dark-blue, 0.7);
          }
        }

        &.open {
          .mi-icon path {
            fill: rgba($zf-dark-blue, 0.7);
          }
        }

        &.active {
          .mi-icon path {
            fill: $zf-cyan-100;
          }

          &:hover {
            .mi-icon path {
              fill: rgba($zf-cyan-100, 0.7);
            }
          }
        }

        &.changed {
          .mi-icon path {
            fill: $zf-yellow;
          }

          &:hover {
            .mi-icon path {
              fill: rgba($zf-yellow, 0.7);
            }
          }
        }

        &.deleted {
          .mi-icon path {
            fill: $zf-red;
          }

          &:hover {
            .mi-icon path {
              fill: rgba($zf-red, 0.5);
            }
          }
        }
      }

      &:hover {
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);

        input {
          border-style: dashed;
          border-color: $zf-cyan-25;
          border-width: 2px;

          &:disabled {
            border-color: white;
          }
        }

        &.off {
          input {
            border-color: white;

            &:disabled {
              border-color: $zf-grey;
            }
          }
        }

        .mi-icon path {
          fill: rgba($zf-dark-blue, 0.4);
        }
      }
    }
  }
}

.element-popover {
  padding: 10px;
  width: 386px;

  h5 {
    padding: 0;
    margin: 0;
    margin-bottom: 5px;
  }
}

.btn {
  transition: background-color 400ms linear;
}

.element-dialog-title,
.day-dialog-title {
  display: flex;
  justify-content: space-between;
  user-select: none;

  button.hidden {
    cursor: default;
    .mi-icon path {
      fill: white;
    }

    &:hover {
      background-color: white;
    }
  }
}

.day-dialog {
  header {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 10px;
    margin-bottom: 10px;

    .hours {
      display: flex;
      gap: 10px;
    }
  }

  .day-element-line {
    display: grid;
    grid-template-columns: 1fr auto;

    h5,
    h6,
    small {
      margin: 0;
      display: inline;
    }

    > .hours {
      grid-column: 2;
      align-self: start;
      grid-row: 1 / span 3;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 5px;
      gap: 10px;
    }

    .comment {
      grid-column: 1 / span 2;
      border-top: 1px solid $zf-black-25;
      padding-top: 5px;
      display: grid;
      grid-template-columns: auto 1fr;
      column-gap: 5px;

      .mi-icon path {
        fill: $zf-black-25;
      }

      textarea {
        height: 24px;
        padding: 0;
        font-size: 13px;
        color: $zf-cyan-100;
        border: none;

        &::placeholder {
          font-style: italic;
        }

        &:disabled {
          background-color: unset;
        }
      }

      &.active {
        .mi-icon path {
          fill: $zf-cyan-100;
        }
      }

      &.changed {
        .mi-icon path {
          fill: $zf-yellow;
        }
        textarea {
          color: $zf-yellow;
        }
      }

      &.deleted {
        .mi-icon path {
          fill: $zf-red;
        }
        textarea {
          color: $zf-red;
        }
      }
    }
  }
}

.element-dialog {
  header {
    display: grid;
    grid-template-columns: 1fr auto;
    margin-bottom: 30px;

    h5,
    h6,
    small {
      margin: 0;
      display: inline;
      line-height: 20px;
    }

    .header-title {
      display: flex;
      flex-direction: column;
    }
  }

  .end-time {
    grid-column: 2/3;
    grid-row: 1/2;
    display: flex;
    font-size: 14px;
    color: $zf-secondary;
  }

  .hours {
    grid-row: 1/2;
    grid-column: 3/4;
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    gap: 10px;
  }
}

.day-dialog {
  display: flex;
  flex-direction: column;
  max-height: 500px;
  overflow: auto;

  > div {
    border: 1px solid #bbb;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    background-color: white;
    margin: 4px;
    padding: 4px;

    &:hover {
      box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
    }
  }
}

.animate {
  header,
  svg,
  input {
    animation: blink 1s;
  }
}

.animate2 {
  header,
  svg,
  input {
    animation: blink2 1s;
  }
}

.animate-day-dialog {
  input,
  textarea,
  header > div.hours > div > div {
    animation: blink 1s;
  }
}

.animate-day-dialog2 {
  input,
  textarea,
  header > div.hours > div > div {
    animation: blink2 1s;
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink2 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
