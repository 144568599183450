.content {
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow-y: auto;
}

.content-left {
  flex-shrink: 0;
}

.content-right {
  width: 100%;
  flex: 1 1 auto;
  overflow-y: auto;
}
