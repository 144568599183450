.content-left {
  width: 0;
  border-right: 2px solid $zf-grey;
  transition-property: width;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
  overflow: hidden;
  display: grid;
  grid-template-rows: 36px 1fr;

  &.active {
    width: 200px;
  }
}

.filters-title {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  transition: 0.4s linear;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $zf-grey;
  margin: 2px;

  span {
    font-family: Rubik-Medium, sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.3;
    padding: 5px;
    padding-left: 9px;
    width: 100%;
  }

  button {
    border: none;
    padding: 5px;
    background: transparent;

    .mi-icon path {
      fill: rgb(191, 191, 191);
      transition: 0.4s linear;
    }

    &:hover {
      .mi-icon path {
        fill: $zf-blue;
      }
    }

    &.pinned {
      .mi-icon path {
        fill: $zf-blue;
      }
    }
  }
}

.filters {
  padding: 10px;
  overflow-y: auto;
  margin: 2px;
  width: 194px;

  .input {
    position: relative;

    .close-button {
      position: absolute;
      right: -5px;
      top: -2px;
    }
  }

  input {
    height: 30px;
  }

  .input-checkbox {
    display: flex;

    input {
      height: 23px;
    }
  }

  .filters-buttons-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .apply {
    height: 30px;
    width: 100%;
    border-radius: 7px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 500;
    cursor: pointer;
    margin-bottom: 5px;
  }
}
