.modal-body {
  padding-top: 0;
  padding-bottom: 0;
}

.modal-header {
  .close {
    padding-right: 2.2rem;
    font-size: 1.6525rem;
    line-height: 0.8;
    color: cyan;
    opacity: 1;
  }
}
