iframe {
  border: 1px solid #ccc;
  background-color: white;
}

.login-dialog {
  z-index: 101;

  &.non-visible {
    display: none;
  }
}
