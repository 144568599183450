.hours-tag {
  font-weight: 500;
  font-family: Rubik-Medium, sans-serif;
  font-size: 17px;
  line-height: 1;
  display: grid;
  grid-template-columns: auto auto;
  background-color: $zf-cyan-100;
  transition: background-color 400ms linear;
  color: white;
  .mi-icon path {
    fill: white;
  }

  padding: 3px;
  border-radius: 7px;

  > div {
    small {
      color: $zf-cyan-25;
      transition: color 400ms linear;
    }

    margin: 3px;
    > div {
      display: inline;
    }
  }

  &.gray {
    background-color: $zf-black-25;
    > div {
      small {
        color: $zf-grey;
      }
    }
  }

  &.yellow {
    background-color: $zf-yellow;
    > div {
      small {
        color: lighten($zf-yellow, 30);
      }
    }
  }

  &.red {
    background-color: $zf-red;
    > div {
      small {
        color: lighten($zf-red, 30);
      }
    }
  }

  &.green {
    background-color: $zf-green;
    > div {
      small {
        color: lighten($zf-green, 30);
      }
    }
  }

  &.text {
    background-color: white;
    color: $zf-cyan-100;
    .mi-icon path {
      fill: $zf-cyan-100;
    }
  }
}
