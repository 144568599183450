/* Outlined badges for all theme colors, e.g. .badge-outline-primary */
@each $color, $value in $theme-colors {
  .badge-outline-#{$color} {
    color: $value;
    background-color: $white;
    box-shadow: 0 0 0 1px $value inset;
  }
}

.badge-warning {
  color: $white;
  text-shadow: 0 0 14px #ad6919ba;
}
