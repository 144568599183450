/* Colors  */
$zf-blue: #175386  !default; //#0070b4 
$zf-cyan-100: #175386 !default; //#00abe7
$zf-cyan-75: #A9D7FF !default; //#70cff3
$zf-cyan-50: #7fd5f3 !default; //#7fd5f3
$zf-cyan-25: #C5E4FF !default;//#bfeaf9 
$zf-black: #000 !default;
$zf-dark-grey: #333 !default;
$zf-black-50: #7f7f7f !default;
$zf-black-25: #BDC1C5!default; //#bfbfbf
$zf-white: #fff !default;
$zf-grey: #EAF0F4 !default; //#eef1f4
$zf-dark-blue: #001024 !default;
$zf-red: #E91051 !default; //#dd0c29
$zf-green: #30ab5b !default;
$zf-yellow: #e8bf29 !default;

$zf-secondary: #627075 !default; //#627075

/* Typography */
$zf-font-prometo-md: "Rubik-Medium", sans-serif !default;
$zf-font-prometo-lt: "Rubik-Light", sans-serif !default;
$zf-font-verdana-rg: "Rubik", sans-serif !default;
$zf-font-verdana-bd: "Rubik-Bolt", sans-serif !default;
$zf-font-verdana-it: "Rubik-Italic", sans-serif !default;
