.splash-screen {
  position: absolute;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  z-index: 100;
  opacity: 1;
  transition: opacity 0.7s ease;
  background-color: #fff;

  &.done {
    opacity: 0;
    user-select: none;
    pointer-events: none;
  }

  img {
    width: 322px;
    height: 322px;
  }

  .spinner {
    position: relative;
    opacity: 1;

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    &::after {
      position: absolute;
      content: ' ';
      left: 0;
      top: 0;
      border: 3px solid $zf-black-25;
      border-top-color: white;
      border-radius: 50%;
      width: 322px;
      height: 322px;
      animation: spin 1.1s linear infinite;
    }
  }
}
