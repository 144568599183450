.table {
  line-height: 1.3rem;
}

.table tbody tr {
  border-top: 1px solid $zf-grey;
}

.table-striped tbody tr {
  border-top: inherit;
}
